import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}

const NotFoundPage = ({ data }) => (
  <Layout>
    <div style={ style }>
      <Helmet title={ `Page Not Found | ${data.site.siteMetadata.title}` }>
          <meta name="description" content="You found a page that doesn't exist." />
      </Helmet>
      <h1>404: This page doesn't exist!</h1>
      <h2>
        Perhaps you'd like to go back to our <Link to="/">home page</Link>?
      </h2>
    </div>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query FourOhFourQuery {
    ...siteTitle
  }
`
